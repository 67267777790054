/* Add this style to your CSS or styles */
.scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent; /* Adjust the color as needed */
  }
  
  .scrollbar-thin::-webkit-scrollbar {
    width: 8px; /* Adjust the width as needed */
  }
  
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: transparent; /* Adjust the color as needed */
  }
  
  .scrollbar-thin::-webkit-scrollbar-track {
    background-color: transparent; /* Adjust the color as needed */
  }
  
  .fixed-line {
    width: max-content;
    display: block!important;

  }



  .cartItem {
    display: grid;
    grid-template-columns: 8fr 1fr 1fr;
    text-align: left;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
  }

  .cartItem hr {
    border: none;
    height: 1px;
    background-color: #000;
    display: grid;
    margin: auto;
    
  }
  
  /* Styles for the Book Now button */
  .bookNowButton {
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 8px;
    border: none;
    border-radius: 5px;
    display: block;
    left: 5%;
  }