
@media only screen and (max-width: 600px) {
    .popup-wrap {
      background-color: #fff;
      width: 100%;
      min-height: 100%;
      height: max-content;
      left: 0;
      top: 0;
      right: 0;
      position: fixed;
      z-index: 999 !important;
      overflow-x: auto;
    }
    .searchWrap {
      display: grid;
      width: 90%;
      margin: auto;
      text-align: center;
      align-items: center;
      height: 80vh;
      grid-template-rows: auto;
    }
    .geobutton {
        border-radius: 2vw;
        background-color: #9b29f2;
        width: 60vw;
        color: #fff;
        padding: 4vw;
        font-size: 4vw;
        display: block;
        margin: 4vw auto;
    }

    .SearchLocationHeading {
      color: #000f0f;
      font-size: 8vw;
      margin: 6vw auto;
      width: 100%;
      text-align: center;
    }

    #feelaxo {
      font-weight: 1000;
      text-align: center;
      width: 100%;
      margin: 2vw auto;
      font-size: 10vw;
    }
    #bold {
      font-weight: 1000;
      font-size: 5vw; 
      margin: 6vw auto 2vw auto;
    }

    .cityButton {
      background-color: #000;
      border-radius: 1vw;
      color: #fff;
      font-weight: bold;
      display: inline-block;
      margin: 2vw;
      padding: 4vw;
      width: 80vw;
    }
    
    .city_button_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 1vw;
      max-width: 100%;
      margin-bottom: 4vw;
  }
  .feelaxo-logo {
    width: 60vw;
    display: grid;
    margin: auto;
    margin-top: 20vw;
  }
  
  /* .city_button_wrap > * {
      flex: 1 1 15vw;
      max-width: calc(30vw - 0.5vw); 
  } */
  }
  

  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .popup-wrap {
      background-color: #fff;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      position: fixed;
      z-index: 999!important;
  }
  .feelaxo-logo {
    width: 60vw;
    display: grid;
    margin: auto;
    margin-top: 20vw;
  }
  .searchWrap {
    display: grid;
    width: 90%;
    margin: auto;
    text-align: center;
    align-items: center;
    height: 80vh;
    grid-template-rows: auto;
  }
  .geobutton {
      border-radius: 2vw;
      background-color: #9b29f2;
      width: 40vw;
      color: #fff;
      padding: 4vw;
      font-size: 4vw;
      display: block;
      margin: 4vw auto;
  }

  .SearchLocationHeading {
    color: #000f0f;
    font-size: 8vw;
    margin: 6vw auto;
    width: 100%;
    text-align: center;
  }

  #feelaxo {
    font-weight: 1000;
    text-align: center;
    width: 100%;
    margin: 2vw auto;
    font-size: 10vw;
  }
  #bold {
    font-weight: 1000;
    font-size: 5vw; 
    margin: 6vw auto 2vw auto;
  }

  .cityButton {
    background-color: #000;
    border-radius: 1vw;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    margin: 2vw;
    padding: 4vw;
    width: 90vw;
  }
  
  
  .city_button_wrap {
    display: flex; /* Change display to flex */
    justify-content: center; /* Center the wrapper horizontally */
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    gap: 1vw;
    max-width: 100%; /* Ensure the wrapper doesn't exceed the viewport width */
    margin-bottom: 4vw;
}

.city_button_wrap > * {
    flex: 1 1 15vw; /* Adjust flex properties for the children */
    max-width: calc(30vw - 0.5vw); /* Adjust max-width to account for the gap */
}
  }
  

  /*desktop*/

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .popup-wrap {
      background-color: rgba(0,0,0,0.7);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      position: fixed;
      z-index: 50;
    }
    
    .searchWrap {
      background-color: rgba(255, 255, 255, 1);
      width: 70%;
      display: grid;
      border-radius: 0.5vw;
      height: max-content;
      margin: auto;
      margin-top: 5vw;
      text-align: center;
      align-items: center;
      vertical-align: middle;
      grid-template-rows: auto;
    }
    .geobutton {
      background-color: #9b29f2;
      width: 30vw;
      color: #fff;
      padding: 1vw;
      font-size: 1vw;
      display: block;
      border-radius: 0.5vw;
      margin: 2vw auto 2vw auto;
  }

  .SearchLocationHeading {
    color: #000f0f;
    font-size: 2vw;
    margin: 2vw auto;
    width: 100%;
    text-align: center;
  }

  #feelaxo {
    font-weight: 800;
    text-align: center;
    width: 100%;
    margin: 2vw auto;
    font-size: 3vw;
  }
  #bold {
    font-weight: 1000;
    font-size: 1vw; 
    margin: 1vw auto 1vw auto;
  }
  .cityButton {
    background-color: #000;
    padding: 0.5vw;
    border-radius: 0.2vw;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    margin: 0 1vw;
  }
  .feelaxo-logo {
    width: 20vw;
    display: grid;
    margin: auto;
    margin-top: 4vw;
  }
  .city_button_wrap {
    display: flex; /* Change display to flex */
    justify-content: center; /* Center the wrapper horizontally */
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    gap: 0.5vw;
    max-width: 100%; /* Ensure the wrapper doesn't exceed the viewport width */
    margin-bottom: 4vw;
}

.city_button_wrap > * {
    flex: 1 1 15vw; /* Adjust flex properties for the children */
    max-width: calc(15vw - 0.5vw); /* Adjust max-width to account for the gap */
}
  }
  

  @media only screen and (min-width: 1025px) {
    .popup-wrap {
      background-color: rgba(0,0,0,0.7);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      position: fixed;
      z-index: 50;
    }
    .feelaxo-logo {
      width: 15vw;
      display: grid;
      margin: auto;
    }
    
    .searchWrap {
      background-color: rgba(255, 255, 255, 1);
      width: 70%;
      display: grid;
      border-radius: 0.5vw;
      height: max-content;
      margin: auto;
      margin-top: 5vw;
      text-align: center;
      align-items: center;
      vertical-align: middle;
      grid-template-rows: auto;
    }
    .geobutton {
      background-color: #9b29f2;
      width: 30vw;
      color: #fff;
      padding: 1vw;
      font-size: 1vw;
      display: block;
      border-radius: 0.5vw;
      margin: 2vw auto 2vw auto;
  }

  .SearchLocationHeading {
    color: #000f0f;
    font-size: 2vw;
    margin: 2vw auto;
    width: 100%;
    text-align: center;
  }

  #feelaxo {
    font-weight: 800;
    text-align: center;
    width: 100%;
    margin: 2vw auto;
    font-size: 3vw;
  }
  #bold {
    font-weight: 1000;
    font-size: 1vw; 
    margin: 1vw auto 1vw auto;
  }
  .cityButton {
    background-color: #000;
    padding: 0.5vw;
    border-radius: 0.2vw;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    margin: 0 1vw;
  }
  
  .city_button_wrap {
    display: flex; /* Change display to flex */
    justify-content: center; /* Center the wrapper horizontally */
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    gap: 0.5vw;
    max-width: 100%; /* Ensure the wrapper doesn't exceed the viewport width */
    margin-bottom: 4vw;
}

.city_button_wrap > * {
    flex: 1 1 15vw; /* Adjust flex properties for the children */
    max-width: calc(15vw - 0.5vw); /* Adjust max-width to account for the gap */
}
  }


  #explore {
    background-color: #fff;
    color: #000;
    border: black solid 2px;
  }