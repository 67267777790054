/* Assuming you are using Tailwind CSS, add this to your styles */
.scrollbar::-webkit-scrollbar {
    width: 12px;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
  }
  